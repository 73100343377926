body {
  margin: 0;
}

#root {
  display: flex;
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  justify-content: center;
  font-family: Nunito, sans-serif;
  background: #fbfbf9;
}

.card {
  border: 1px solid rgb(221, 221, 221);
  box-shadow: rgb(170, 170, 170) 0px 1px 2px;
  padding: 10px 15px;
  background: white;
}

.card:hover {
  box-shadow: rgb(100, 100, 100) 0px 1px 3px;
}
